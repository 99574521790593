<template>
  <v-sheet :class="darkmode ? darkColor : 'white'">
    <v-container class="px-4">
      <v-expand-transition>
        <v-sheet
          v-if="$vuetify.breakpoint.smAndUp && selected != null"
          color="grey lighten-4"
          height="350"
          class="rounded-lg"
          tile
        >
          <v-row class="fill-height" align="center" justify="center">
            <v-img
              :src="selected.thumbnail"
              gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
              class="rounded-lg"
              height="350px"
            >
              <v-row class="fill-height ml-10" align="center">
                <v-card-text class="text-left white--text">
                  <p class="display-1">{{ selected.title }}</p>
                  <div class="card-caption-wrap">
                    <span v-html="truncateHtml(selected.description)" />
                    <span
                      class="white--text"
                      v-if="selected.description == isFalse"
                      >{{ $t("homepage.no_description") }}</span
                    >
                  </div>
                  <v-btn
                    large
                    class="gradient-primary"
                    dark
                    @click="
                      $router.push({
                        name: 'InformationDetail',
                        params: { type: 'news' },
                        query: {
                          id: selected.id,
                          title: join(selected.title)
                        }
                      })
                    "
                    >{{ $t("homepage.read") }}</v-btn
                  >
                </v-card-text>
              </v-row>
            </v-img>
          </v-row>
        </v-sheet>
      </v-expand-transition>
      <v-slide-group
        v-model="activeNews"
        class="pt-4 d-flex justify-center"
        show-arrows="always"
        :mandatory="slides.length > 0"
        center-active
      >
        <v-slide-item
          v-for="(slide, i) in slides"
          :key="i"
          v-slot:default="{ active }"
        >
          <v-card
            outlined
            class="text-left"
            :class="
              $vuetify.breakpoint.smAndUp
                ? active
                  ? 'news-active ma-4'
                  : 'news-default ma-4'
                : 'news-active-mob ma-4'
            "
            @click="setActiveNews(slide, i)"
          >
            <v-img
              v-if="slide.file_type == 'youtube' || slide.file_type == 'image'"
              :src="slide.thumbnail"
              :height="active ? '170' : '150'"
            ></v-img>
            <div v-else class="pa-4">
              <v-img
                :src="slide.thumbnail"
                :height="active ? '140' : '120'"
                contain
              ></v-img>
            </div>

            <v-card-text class="subtitle-1">
              <div class="text-truncate">{{ slide.title }}</div>
              <div class="caption card-caption-wrap">
                <span v-html="truncateHtml(slide.description)" />
                <span v-if="slide.description == isFalse">{{
                  $t("homepage.no_description")
                }}</span>
              </div>
              <span
                class="font-weight-light text-truncate"
                style="font-size: 11px"
              >
                {{ $t("homepage.posted_on_date") }}
                {{ dateFormat(slide.created_date) }}
              </span>

              <span class="font-weight-light" style="font-size: 11px">
                {{ $t(`app.${slide.type}`) }} {{ $t(`app.for`) }}
                <span v-if="slide.type_for == 'kelas_tertentu'">
                  {{ $t("app.class") }} {{ slide.name }}
                </span>
                <span v-else>{{ $t(`app.${slide.type_for}`) }}</span>
              </span>

              <v-row no-gutters style="font-size: 11px">
                <a class="mr-2">
                  <v-icon
                    :color="slide.status_likes ? 'primary' : ''"
                    small
                    @click="likeHandler(slide.id)"
                    >mdi-thumb-up-outline</v-icon
                  >
                  <span
                    :style="darkmode ? 'color: white' : 'color: black'"
                    class="ml-1"
                    >{{ slide.likes }}</span
                  >
                </a>
                <a class="mr-2">
                  <v-icon small>mdi-eye-outline</v-icon>
                  <span
                    :style="darkmode ? 'color: white' : 'color: black'"
                    class="ml-1"
                    >{{ slide.view }}</span
                  >
                </a>
                <v-spacer />
                <SocialSharing small :data="setSharing(slide)" />
              </v-row>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
      <div class="text-center">
        <v-btn
          text
          color="primary"
          small
          @click="$router.push('/information/news')"
          >{{ $t("homepage.view_more") }}</v-btn
        >
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
import SocialSharing from "@/components/SocialSharing";
import {
  getInformation,
  setLikes,
  getInformationEmployee
} from "@/api/admin/information";
import truncate from "truncate-html";
import moment from "moment/moment";
moment.locale("id");
import { mapGetters } from "vuex";

export default {
  props: {
    darkColor: {
      type: String,
      default: "black"
    }
  },
  components: {
    SocialSharing
  },
  created() {
    this.getListData();
  },
  computed: {
    ...mapGetters(["g_token"]),
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  data() {
    return {
      queryData: {
        limit: 8,
        type: "news",
        sort: "created_at",
        order: "DESC"
      },
      selected: null,
      activeNews: null,
      isFalse: "",
      slides: [],
      thumbnail: {
        pdf: require("@/assets/svg/thumbnail_pdf.svg"),
        video: require("@/assets/svg/thumbnail_video.svg"),
        nofilenews: require("@/assets/svg/thumbnail_nofile_news.svg")
      }
    };
  },
  methods: {
    setSharing(data) {
      const setUrl = `${window.location.origin}/information/news/detail?id=${
        data.id
      }&title=${this.join(data.title)}`;
      return {
        url: setUrl,
        title: data.title,
        description: data.description
      };
    },
    join(title) {
      return title.split(" ").join("-");
    },
    likeHandler(id) {
      if (this.g_token) {
        this.$Progress.start();
        setLikes({ id: id }).then(res => {
          if (res.data.code) {
            this.getListData();
          }
          this.$Progress.finish();
        });
      } else {
        this.$router.push("/login").then(() => {
          this.$store.commit("CALL_SNACKBAR", {
            msg: this.$i18n.t("app.login_first"),
            color: "warning"
          });
        });
      }
    },
    dateFormat: date => moment(date).format("DD MMM YYYY"),
    truncateHtml: html => truncate(html, 4, { byWords: true, stripTags: true }),
    splitUrl(url, onlyCode) {
      if (url) {
        const splitUrl = url.split("watch?v=");
        if (
          splitUrl[0] == "https://www.youtube.com/" ||
          splitUrl[0] == "www.youtube.com/"
        ) {
          let idYoutube = url.split("v=")[1];
          var ampersAndPosition = idYoutube.indexOf("&");
          if (ampersAndPosition != -1) {
            idYoutube = idYoutube.substring(0, ampersAndPosition);
          }
          if (onlyCode && idYoutube) {
            return idYoutube;
          } else if (onlyCode && !idYoutube) {
            return "";
          } else {
            return "https://www.youtube.com/embed/" + idYoutube;
          }
        }
      }
    },
    async getListData() {
      let response = [];
      try {
        if (this.g_token) {
          response = await getInformationEmployee(this.queryData);
        } else {
          response = await getInformation(this.queryData);
        }
        if (response.data.code) {
          const tempData = [];
          const data = response.data.data.data;

          data.map(r => {
            let thumbnail = "";
            if (r.file_type) {
              switch (r.file_type) {
                case "youtube": {
                  const code = this.splitUrl(r.thumbnail, "onlyCode");
                  if (code)
                    thumbnail = `https://img.youtube.com/vi/${code}/0.jpg`;
                  break;
                }
                case "pdf":
                  thumbnail = this.thumbnail.pdf;
                  break;
                case "video":
                  thumbnail = this.thumbnail.video;
                  break;
                default:
                  thumbnail = r.thumbnail;
                  break;
              }
            } else {
              thumbnail = this.thumbnail.nofilenews;
            }
            tempData.push({ ...r, thumbnail: thumbnail });
          });

          this.slides = tempData;
          this.total = response.data.data.total;
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: response.data.message,
            color: "error"
          });
        }
      } catch (error) {
        this.$store.commit("CALL_SNACKBAR", {
          msg: error,
          color: "error"
        });
      }
    },
    setActiveNews(param, idx) {
      this.$nextTick(() => {
        this.selected = param;
        this.activeNews = idx;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-caption-wrap {
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-active {
  width: 250px;
  height: 350px;
}
.news-active-mob {
  width: 250px;
  height: 325px;
}
.news-default {
  width: 200px;
  height: 325px;
  opacity: 0.5;
}
</style>
